



















import Vue from 'vue'
import { fetchAdministrator, updateDealerAdminPermissions } from '@api/addy-plus/account-administrators'
import { IData, IMethods, IComputed } from '@/types/addy_plus/accounts/dealer_permissions'
// @ts-ignore
import pageTitleMixin from '@utils/mixins/addy-plus-page-title-mixin.js'
import Flash from '@components/modal/slide-down-flash.vue'
import Breadcrumb from '@components/breadcrumb/breadcrumb.vue'

export default Vue.extend<IData, IMethods, IComputed>({
  title: 'Account Administrators: Permissions',
  mixins: [pageTitleMixin],
  components: {
    Flash,
    Breadcrumb,
  },
  data() {
    return {
      administrator: {
        email: null,
        corporations: [],
      },
      flashContent: '',
      timer: null,
      isSaving: false,
      corporationPermissions: null,
    }
  },
  computed: {
    accountId() {
      return this.$route.params.accountId
    },
    adminId() {
      return this.$route.params.adminId
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      fetchAdministrator(this.accountId, this.adminId).then((result) => {
        this.administrator = result.data
      })
    },
    onUpdateCheckbox(value, hashid) {
      if (!this.corporationPermissions) {
        this.corporationPermissions = {}
      }
      this.corporationPermissions[hashid] = !!value
    },
    savePermissions() {
      this.isSaving = true
      updateDealerAdminPermissions(this.accountId, this.adminId, { corporation_permissions: this.corporationPermissions }).then((result) => {
        if (!result.success) return

        this.flashContent = result.data.message

        this.timer && clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.hideFlash()
        }, 4000)
      }).finally(() => {
        this.isSaving = false
      })
    },
    hideFlash() {
      this.flashContent = ''
    },
  },
})
